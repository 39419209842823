/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getAllWorkflows, getWorkflow } from '@/api/workflowsMethods';

const state = () => ({
  workflowCustomField: [],
  workflowsList: null,
  workflow: null,
});

const getters = {
  workflowsList: state => {
    return state.workflowsList;
  },
  workflow: state => {
    return state.workflow;
  },
  workflowCustomField: state => {
    return state.workflowCustomField;
  },
};

const mutations = {
  SET_WORKFLOWS_LIST (state, payload) {
    state.workflowsList = payload;
  },
  SET_WORKFLOW (state, payload) {
    state.workflow = payload;
    state.workflowCustomField = payload
      ? payload.searches
        .map((search) => search.custom_fields_keys)
        .flat()
      : [];
  },
  SET_CUSTOM_FIELDS (state, payload) {
    const newCustomField = payload.map((field) => field.name);
    let customFields = state.workflowCustomField;
    if (payload.length) {
      customFields = [...new Set([...customFields, ...newCustomField])];
    }
    state.workflowCustomField = customFields;
  },
  DELETE_CUSTOM_FIELDS (state, payload) {
    let customFields = state.workflowCustomField;
    customFields = customFields.filter((field) => !payload.includes(field));
    state.workflowCustomField = customFields;
  },

  UPDATE_WORKFLOW (state, { id, update }) {
    if (!id) {
      throw new Error('Id is required');
    }
    const workflowsList = state.workflowsList;
    const workflow = state.workflowsList.find(workflow => workflow._id === id);
    if (workflow) {
      const updated = { ...workflow, ...update };
      workflowsList.splice(state.workflowsList, 1, updated);
    }
  },

  UPDATE_WORKFLOWS_LIST (state, payload) {
    const workflowsList = state.workflowsList;
    const isDeleted = payload.status === 'deleted';
    const existingIndex = state.workflowsList.findIndex(workflow => workflow._id === payload._id);
    if (isDeleted) {
      return;
    }
    if (existingIndex > -1) {
      workflowsList.splice(existingIndex, 1, payload);
    } else {
      workflowsList.push(payload);
    }
    state.workflowsList = workflowsList;
  },
};

const actions = {
  async SET_WORKFLOWS_LIST ({ commit, rootState }, payload) {
    const workflows = payload || (rootState.credentials.selectedCredential?._id ? await getAllWorkflows(rootState.credentials.selectedCredential._id) : []);
    commit('SET_WORKFLOWS_LIST', workflows);
    return workflows || [];
  },
  async SET_WORKFLOW ({ commit }, payload) {
    const workflow = await getWorkflow(payload.workflowId, payload.selectedCredentialId);
    commit('SET_WORKFLOW', workflow);
    return workflow;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
