import { Editor } from '@tiptap/vue-2';
export var EditorTextAlignment;
(function (EditorTextAlignment) {
    EditorTextAlignment["LEFT"] = "left";
    EditorTextAlignment["CENTER"] = "center";
    EditorTextAlignment["RIGHT"] = "right";
    EditorTextAlignment["JUSTIFY"] = "justify";
})(EditorTextAlignment || (EditorTextAlignment = {}));
export class EditorButton {
    id;
    attributes;
    icon;
    parent;
    action;
    constructor({ id, attributes, icon, action }) {
        this.id = id;
        this.attributes = attributes;
        this.icon = icon;
        this.action = action;
    }
}
export class EditorAlignButton extends EditorButton {
    constructor(alignment) {
        super({
            id: 'align-' + alignment,
            attributes: { textAlign: alignment },
            icon: 'bx-align-' + (alignment === 'center' ? 'middle' : alignment),
            action: (editor) => {
                editor.chain().setTextAlign(alignment).focus().run();
            },
        });
    }
}
export class EditorMarkButton extends EditorButton {
    constructor(mark) {
        super({
            id: mark,
            attributes: mark,
            icon: 'bx-' + mark,
            action: (editor) => {
                editor.chain().toggleMark(mark).focus().run();
            },
        });
    }
}
export class EditorLinkButton extends EditorButton {
    constructor() {
        super({
            id: 'link',
            attributes: 'link',
            icon: 'bx-link-alt',
            action: (editor) => {
                const attrs = editor.getAttributes('link');
                const href = attrs?.href || '';
                editor.chain().toggleLink({ href }).focus().run();
            },
        });
    }
}
