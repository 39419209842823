export class FeatureStatus {
    active;
    upgrade;
    exist;
    constructor(active, exist) {
        this.active = active;
        this.exist = exist;
        this.upgrade = !active && exist;
    }
}
