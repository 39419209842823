export class DraftMessage {
    profileId;
    draft;
    type;
    subject;
    constructor(profileId, draft, type, subject) {
        this.profileId = profileId;
        this.draft = draft;
        this.type = type;
        this.subject = subject;
    }
}
