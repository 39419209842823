import { getAttributes } from '@tiptap/vue-2';
import { Plugin, PluginKey } from '@tiptap/pm/state';
export function handleModClickLink() {
    return new Plugin({
        key: new PluginKey('handleModClickLink'),
        props: {
            handleClick: (view, pos, event) => {
                if (event.button !== 0) {
                    return false;
                }
                const eventTarget = event.target;
                if (eventTarget.nodeName !== 'A') {
                    return false;
                }
                if (event.metaKey) {
                    const attrs = getAttributes(view.state, 'link');
                    const link = event.target;
                    const href = link?.href ?? attrs.href;
                    const target = link?.target ?? attrs.target;
                    if (link && href) {
                        if (view.editable) {
                            window.open(href, target);
                        }
                        return true;
                    }
                }
                return false;
            },
        },
    });
}
