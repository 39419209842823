import { onBeforeUnmount, ref, watch } from 'vue';
import { notifyError } from './notifyError';
import { LoaderOptions } from '@/mixins/loaderMixin';
import { useLoading } from './useLoading';

/** @type {use.loadingWrapper} */
const loadingWrapper = (func, loading, onErrorReturn = null) => {
  func = notifyError(func, onErrorReturn);
  const newFunction = async (...args) => {
    try {
      if (loading.value) { return; }
      loading.value = true;
      // @ts-ignore
      return await func(...args);
    } finally {
      loading.value = false;
    }
  };
  return newFunction;
};

/**
 * @typedef LoaderExtraOptions
 * @property {import('vue').Ref<HTMLElement | null> | HTMLElement | null} [container]
 * @property {number} [width]
 * @property {number} [height]
 * @property {number} [opacity]
 * @property {boolean} [isFullPage]
 */

/** @param {LoaderExtraOptions} [extraOptions] */
const useLoader = (extraOptions) => {
  const options = new LoaderOptions(extraOptions);
  const plugin = useLoading(options);
  const vldParent = ref(null);
  let isShown = false;
  let loader = null;
  /** @param {import('vue').Ref} [loading] */
  const loaderWatcher = (loading) => {
    watch(loading, (loadingVal) => {
      if (loadingVal) {
        if (isShown) { return; }
        isShown = true;
        if (!options.container) {
          options.container = vldParent.value;
        }
        loader = plugin.show(options);
      } else {
        isShown = false;
        loader?.hide();
      }
    });
  };

  /** @type {use.loadingWrapper} */
  const withLoader = (func, loading) => {
    const wrappedFunction = loadingWrapper(func, loading);
    loaderWatcher(loading);
    return wrappedFunction;
  };
  onBeforeUnmount(() => {
    isShown = false;
    loader?.hide();
  });
  return { withLoader, loaderWatcher, vldParent };
};
export { useLoader, loadingWrapper };
