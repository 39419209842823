export var PausedReason;
(function (PausedReason) {
    PausedReason["INMAIL_WITH_MESSAGES"] = "inmail_with_messages";
    PausedReason["FAILED_MESSAGE"] = "failed_message";
    PausedReason["FAILED_EMAIL"] = "failed_email";
    PausedReason["TALKED_BEFORE"] = "talked_before";
    PausedReason["TALKED_BEFORE_WORKFLOW"] = "talked_before_workflow";
    PausedReason["MANUAL"] = "manual";
    PausedReason["GOT_REPLY"] = "got_reply";
    PausedReason["FIX_DUPLICATES"] = "fix_duplicates";
    PausedReason["CALENDLY"] = "calendly";
})(PausedReason || (PausedReason = {}));
