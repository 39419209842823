import { useStore } from '@/store';
import { computed } from 'vue';
import { addCustomDomain, deleteCustomDomain, setCustomDomainForAll, updateCustomDomain } from '@/api/linkTrackingMethod';
import { notifyError } from '@/use/notifyError';
import { useSnack } from '@/lib/useSnack';

const useDomains = () => {
  const { $snack } = useSnack();
  const store = useStore();
  const customDomains = computed(() => store.getters['customDomains/customDomains']);

  const setDomains = notifyError(async (needRequest = false) => {
    if (!customDomains.value || needRequest) {
      await store.dispatch('customDomains/SET_CUSTOM_DOMAINS');
    }
  });

  const setUpdatedDomains = notifyError(async (domainId, update) => {
    const response = await updateCustomDomain(domainId, update);
    store.commit('customDomains/UPDATE_CUSTOM_DOMAINS', response);
    $snack.success('Domain has been successfully updated');
    return response;
  });

  const setAddedDomains = notifyError(async (newDomain) => {
    const response = await addCustomDomain(newDomain);
    store.commit('customDomains/ADD_DOMAIN', response);
    $snack.success('Domain has been successfully added');
    return response;
  });

  const setDeletedDomains = notifyError(async (domain) => {
    await deleteCustomDomain(domain._id);
    store.commit('customDomains/DELETE_DOMAIN', domain);
    $snack.success('Domain has been successfully deleted');
  });

  const updateUsageCounter = (domain, oldDomainId, flow) => {
    const payload = {
      domain,
      oldDomainId,
      flow,
    };
    store.commit('customDomains/UPDATE_USAGE_COUNTER', payload);
  };

  const useDomainForAll = async (domainId, flow) => {
    const domain = await setCustomDomainForAll(domainId, flow);
    const payload = {
      domain,
      flow,
    };
    store.commit('customDomains/USE_DOMAIN_FOR_ALL', payload);
  };

  return {
    setDomains,
    setUpdatedDomains,
    setAddedDomains,
    setDeletedDomains,
    customDomains,
    updateUsageCounter,
    useDomainForAll,
  };
};

export {
  useDomains
};
