export var WorkflowActionsTypes;
(function (WorkflowActionsTypes) {
    WorkflowActionsTypes["LI_CONNECT"] = "li_connect";
    WorkflowActionsTypes["LI_CONNECT_BY_EMAIL"] = "li_connect_by_email";
    WorkflowActionsTypes["LI_CONNECT_PROFILE"] = "li_connect_profile";
    WorkflowActionsTypes["LI_SEND_MESSAGE"] = "li_send_message";
    WorkflowActionsTypes["LI_SEND_INMAIL"] = "li_send_inmail";
    WorkflowActionsTypes["LI_ACTIONS"] = "li_actions";
    WorkflowActionsTypes["LI_INVITE_EVENT"] = "li_invite_event";
    WorkflowActionsTypes["SEND_EMAIL"] = "send_email";
    WorkflowActionsTypes["SEND_WEBHOOK"] = "send_webhook";
    WorkflowActionsTypes["SEND_SMS"] = "send_sms";
    WorkflowActionsTypes["MANUAL_CALL"] = "manual_call";
    WorkflowActionsTypes["VOICEMAIL_DROP"] = "voicemail_drop";
    WorkflowActionsTypes["ENRICH_PROFILE"] = "enrich_profile";
    WorkflowActionsTypes["PUSH_TO_CRM"] = "push_to_crm";
    WorkflowActionsTypes["MANUAL_TASK"] = "manual_task";
    WorkflowActionsTypes["REVEAL_PERSONALITY"] = "reveal_personality";
})(WorkflowActionsTypes || (WorkflowActionsTypes = {}));
export var WorkflowStatuses;
(function (WorkflowStatuses) {
    WorkflowStatuses["ACTIVE"] = "active";
    WorkflowStatuses["INACTIVE"] = "inactive";
    WorkflowStatuses["DELETED"] = "deleted";
})(WorkflowStatuses || (WorkflowStatuses = {}));
export var SequenceType;
(function (SequenceType) {
    SequenceType["SIMPLE"] = "simple";
    SequenceType["ADVANCED"] = "advanced";
})(SequenceType || (SequenceType = {}));
