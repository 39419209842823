import { getSpaceFeatures } from '@/api/spaceMethods';
import { Tiers } from '@/enums/pricing';
import { FeatureStatus } from '@/components/adminPage/whiteLabelsPage/static/featureStatus';
import { FEATURES } from '@/data/pricing/features';
import { getFeaturesByCategory } from '@/components/adminPage/whiteLabelsPage/static/static';
import store from '@/store';
import { computed, watch } from 'vue';
watch(() => store.getters['workflows/workflowsList'], () => {
    if (tierFeatures.features) {
        tierFeatures._setSpreadFeatures();
        tierFeatures._setLimitFeatures();
    }
});
watch(() => store.getters['integrations/integrationsList'], () => {
    if (tierFeatures.features) {
        tierFeatures._setSpreadFeatures();
        tierFeatures._setLimitFeatures();
    }
});
class TierFeatures {
    features; // массив с бэка - фичи старших тиров и Free
    tier;
    spreadFeatures;
    statuses;
    constructor() {
        this.statuses = {};
        this.tier = null;
        this.features = [];
        this.spreadFeatures = [];
    }
    async initiate(tier, account) {
        this.features = await getSpaceFeatures();
        const featuresIdx = this.features.findIndex(f => f.tier === tier);
        if (featuresIdx === -1) {
            this.features.push({
                tier,
                features_settings: account.features_settings,
                local_prices: {},
            });
        }
        else {
            this.features[featuresIdx].features_settings = account.features_settings;
        }
        this.tier = tier;
        this.features = this.features.sort((a, b) => a.tier - b.tier);
        this._setSpreadFeatures();
        const featuresAccesses = this._getFeatureAccesses();
        this.statuses = this._getStatuses(featuresAccesses);
    }
    _setSpreadFeatures() {
        const workflowsList = computed(() => store.getters['workflows/workflowsList'] || []);
        const integrationsList = computed(() => store.getters['integrations/integrationsList'] || []);
        this.spreadFeatures = [...this.features].map(f => {
            const simpleFeatures = {};
            // убираем лишнюю вложенность
            Object.assign(simpleFeatures, ...Object.values(f.features_settings.simple_features));
            return {
                ai: Object.values(f.features_settings.simple_features.ai_features),
                dialer: Object.values(f.features_settings.simple_features.dialer_features),
                campaigns: !f.features_settings.limited_features.campaigns.is_limited || workflowsList.value.length < f.features_settings.limited_features.campaigns.limit,
                email_integrations: !f.features_settings.limited_features.email_integrations.is_limited || integrationsList.value.length < f.features_settings.limited_features.email_integrations.limit,
                default: getFeaturesByCategory(f.features_settings.credits_features, 'default').default,
                features_settings: simpleFeatures,
                tier: f.tier,
                local_prices: f.local_prices,
                is_enabled: f.is_enabled || false,
            };
        });
    }
    _getFeatureAccesses() {
        return this.spreadFeatures.map(feature => {
            return feature.features_settings;
        });
    }
    _getStatuses(featuresAccesses) {
        const features = {};
        FEATURES.forEach((featureName) => {
            const featureTier = featuresAccesses.map((featureAccess) => {
                const access = featureAccess[featureName];
                if (access === undefined) {
                    // eslint-disable-next-line no-console
                    console.error(new Error(`unknown feature ${featureName}`));
                }
                return access;
            }, {});
            features[featureName] = featureTier;
        });
        return this._getStatusesFromAccess(features, this.tier);
    }
    _findCurrentTierIndex(tier) {
        // берем валуе от энама, фильтруем его по тирам приходящим с бэка и ищем от них индекс нынешнего тира
        // чтоб сопоставить с массивами фичей и понять доступна ли фича в этом тире
        return Object.values(Tiers).filter(t => this.features.some(f => f.tier === t)).findIndex(t => t === tier);
    }
    _getStatusesFromAccess(featureTierAccess, tier) {
        const currentTier = this._findCurrentTierIndex(tier);
        const featuresStatus = {};
        const k = Object.keys(featureTierAccess);
        for (const featureName of k) {
            const tierFeature = featureTierAccess[featureName];
            if (tierFeature === undefined) {
                // eslint-disable-next-line no-console
                console.error(new Error(`unknown feature ${featureName}`));
                continue;
            }
            const active = tierFeature[currentTier];
            const exist = tierFeature.some(f => f);
            featuresStatus[featureName] = new FeatureStatus(active, exist);
        }
        return featuresStatus;
    }
    _setLimitFeatures() {
        const limitFeatures = ['campaigns', 'email_integrations'];
        const features = {};
        limitFeatures.forEach((featureName) => {
            const featureTier = this.features.map((tierFeature) => {
                const limitedFeature = tierFeature.features_settings.limited_features[featureName];
                return this._checkLimitedFeature(featureName, limitedFeature);
            });
            features[featureName] = featureTier;
        });
        const statuses = this._getStatusesFromAccess(features, this.tier);
        this.statuses = Object.assign(this.statuses, statuses);
    }
    _checkLimitedFeature(featureName, featureLimit) {
        const workflowsList = computed(() => store.getters['workflows/workflowsList'] || []);
        const integrationsList = computed(() => store.getters['integrations/integrationsList'] || []);
        switch (featureName) {
            case 'campaigns':
                return Boolean(!featureLimit.is_limited || (workflowsList.value?.length || 0) < featureLimit.limit);
            case 'email_integrations':
                return Boolean(!featureLimit.is_limited || (integrationsList.value?.length || 0) < featureLimit.limit);
            default:
                return true;
        }
    }
}
const tierFeatures = new TierFeatures();
export { tierFeatures };
