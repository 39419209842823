<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <li
    :class="[
      'uikit-list',
      'uikit-list_size_' + size,
      { 'uikit-list_activated': isActivatedList },
      { 'uikit-list_hovered': isHoveredList },
    ]"
  >
    <div class="flex-row flex-gap-8 overflow-hidden">
      <i
        v-if="leftIconClasses || leftIconName"
        :class="`bx bx-${leftIconName} ${leftIconClasses}`"
      />
      <!-- for list of checkboxes and single -->
      <checkbox
        v-if="hasCheckbox"
        :is-active="isActiveCheckbox"
        @select-checkbox="$emit('select', $event)"
      />
      <default-avatar
        v-if="avatarPhotoLink || avatarStatusColor"
        :size="['m', 's'].includes(size) ? 'xxs' : 'xxxs'"
        :photo-link="avatarPhotoLink"
        :status-color="avatarStatusColor"
      />
      <div class="overflow-hidden">
        <h1
          class="text_normal"
          :class="[
            'text_ellipsis',
            titleColor ? 'color_' + titleColor : '',
            titleClass,
          ]"
        >
          {{ title }}
        </h1>
        <slot name="title" />
        <p
          v-if="text"
          :class="textColor ? 'color_' + textColor : ''"
        >
          {{ text }}
        </p>
        <slot name="text" />
      </div>
    </div>

    <div class="flex-row flex-gap-8">
      <p
        v-if="description"
        :class="descriptionColor ? 'text_' + descriptionColor : ''"
      >
        {{ description }}
      </p>
      <default-button
        v-if="buttonTitle || buttonIconName"
        :title="buttonTitle"
        :color="buttonColor"
        :icon-name="buttonIconName"
        :is-disabled="isDisabledButton"
        form="rectangle"
        size="s"
        @action="$emit('button-action')"
      />
      <i
        v-if="rightIconClasses"
        :class="rightIconClasses"
      />

      <default-label
        v-if="label"
        :label-name="label.name"
        :icon-name="label.icon?.name"
        :custom-type="label.customType"
        size="s"
      />
    </div>

    <slot name="trailing" />
  </li>
</template>
<script>
  import Checkbox from '@/components/base/Checkbox';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar.vue';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel.vue';

  export default {
    name: 'DefaultListItem',
    components: { Checkbox, DefaultAvatar, DefaultButton, DefaultLabel },
    props: {
      size: {
        // m, s, xs; отступы элемента настраивать селекторами на компонент
        type: String,
        default: 's',
      },
      // icons begin
      leftIconClasses: {
        type: String,
        default: '',
      },
      rightIconClasses: {
        type: String,
        default: '',
      },
      // icons end
      // checkbox end
      hasCheckbox: {
        type: Boolean,
        default: false,
      },
      leftIconName: {
        type: String,
        default: '',
      },
      // eslint-disable-next-line vue/require-default-prop
      isActiveCheckbox: {
        type: Boolean,
        default: false,
      },
      // checkbox end
      // avatar begin
      avatarPhotoLink: {
        type: String,
        default: '',
      },
      avatarStatusColor: {
        type: String,
        default: '',
      },
      // avatar end

      // text begin
      title: {
        type: String,
        default: '',
      },
      titleColor: {
        type: String,
        default: '',
      },
      titleClass: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      textColor: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      descriptionColor: {
        type: String,
        default: '',
      },
      // text end

      // button begin
      buttonTitle: {
        type: String,
        default: '',
      },
      buttonColor: {
        type: String,
        default: '',
      },
      buttonIconName: {
        type: String,
        default: '',
      },
      isDisabledButton: {
        type: Boolean,
        default: false,
      },
      // button end

      isActivatedList: {
        type: Boolean,
        default: false,
      },
      isHoveredList: {
        type: Boolean,
        default: false,
      },

      label: {
        type: Object,
        default: null,
      },
    },
  };
</script>
<style lang="scss">
.uikit-list {
  @include flex-row-between;

  &_hovered:hover, &_activated {
    transition: $transition-1;
    background: var(--bg-gray-04);
  }

  &_hovered:hover {
    cursor: pointer;
  }

  &_size {
    &_m {
      h1 {
        @include text-m;
        font-weight: $t-weight-accent;
      }
      p {
        @include text-s;
      }
    }

    &_s {
      h1 {
        @include text-s;
        //font-weight: $t-weight-accent;
      }
      p {
        @include text-xs;
      }
    }

    &_xs {
      h1 {
        @include text-xs;
        font-weight: $t-weight-accent;
      }
      p {
        @include text-xs;
      }
    }
  }
}
</style>
