import { useHttp } from '@/middleware/httpMiddleware';

async function addCustomDomain (newDomain) {
  const data = await useHttp(
    '/tracking/custom_domains',
    'POST',
    newDomain,
    {},
    true
  );

  return data.payload;
}
async function getCustomDomains () {
  const data = await useHttp(
    '/tracking/custom_domains',
    'GET',
    null,
    null,
    true
  );

  return data.payload;
}
async function updateCustomDomain (domainId, update) {
  const data = await useHttp(
    `/tracking/custom_domains/${domainId}`,
    'PATCH',
    update,
    null,
    true
  );

  return data.payload;
}
async function deleteCustomDomain (domainId) {
  const data = await useHttp(
    `/tracking/custom_domains/${domainId}`,
    'DELETE',
    null,
    null,
    true
  );

  return data.payload;
}

async function setCustomDomainForAll (domainId, target) {
  const data = await useHttp(
    `/tracking/custom_domains/${domainId}/set_for_all`,
    'POST',
    { target_type: target },
    {},
    true
  );

  return data.payload;
}

export {
  getCustomDomains,
  addCustomDomain,
  updateCustomDomain,
  deleteCustomDomain,
  setCustomDomainForAll
};
