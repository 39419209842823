/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/

import { computed } from 'vue';
import { useStore } from '@/store';

const store = useStore();
export const useSocialName = () => {
  const brand = computed(() => store.getters['user/user']?.brand);
  return computed(() => {
    if (['buzz', 'kennected'].includes(brand.value)) {
      return 'Social';
    }
    return 'LinkedIn';
  });
};
