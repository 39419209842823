<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <validation-observer v-slot="{ passes }">
    <form
      class="flex-row flex-gap-16"
      @submit.prevent="passes(addNewItem)"
    >
      <validation-provider
        v-slot="{ errors }"
        class="flex-grow-1"
        tag="div"
        :rules="validationString"
      >
        <div
          :class="[
            'fillable-input__inner',
            {
              'fillable-input__inner_danger': errors.length,
              'fillable-input__inner_nude': isNude,
              'fillable-input__inner_active': (active && !errors.length),
            }
          ]"
        >
          <div
            v-if="items.length"
            class="flex-row flex-gap-4 flex-wrap"
          >
            <default-label
              v-for="(item, i) in items"
              :key="`${item}${i}`"
              :custom-type="labelType"
              :label-name="item"
              size="xs"
              :has-button="true"
              @click.native="$emit('delete', item)"
            />
          </div>
          <input
            :id="inputId"
            v-model="innerValue"
            class="fillable-input__item"
            :class="`text_${inputSize}`"
            :name="inputName"
            :placeholder="inputPlaceholder"
            @focus="active = true"
            @blur="handleBlur"
          >
          <template v-if="errors.length">
            <p
              v-for="(error, i) in errors"
              :key="`${error}${i}`"
              class="color_danger text_xs"
            >
              {{ error }}
            </p>
          </template>
        </div>
      </validation-provider>
      <default-button
        v-if="isNeedSubmitButton"
        type="submit"
        :title="buttonTitle"
        :color="buttonColor"
        :size="buttonSize"
        :form="buttonForm"
      />
    </form>
  </validation-observer>
</template>
<script>
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';

  import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';

  import * as rules from 'vee-validate/dist/rules';
  import enLocale from 'vee-validate/dist/locale/en.json';
  import { emailRegex } from '@/data/regexes';
  const { messages } = enLocale;

  export default {
    // вынести повторяющийся код для валидации в миксин
    name: 'FillableInput',
    components: {
      DefaultLabel,
      DefaultButton,
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      items: {
        type: Array,
        default: () => [],
      },
      labelType: {
        type: String,
        default: 'info',
      },
      inputName: { // нужно для валидации
        type: String,
        required: true,
      },
      inputId: { // нужно для label
        type: String,
        required: true,
      },
      isNude: {
        type: Boolean,
        default: false,
      },
      inputSize: {
        type: String,
        default: 's',
      },
      inputPlaceholder: {
        type: String,
        default: '',
      },
      buttonTitle: {
        type: String,
        default: function () {
          return this.$t('actions.add');
        },
      },
      buttonColor: {
        type: String,
        default: 'primary',
      },
      isNeedSubmitButton: {
        type: Boolean,
        default: true,
      },
      buttonSize: {
        type: String,
        default: 'm',
      },
      buttonForm: {
        type: String,
        default: 'rectangle',
      },
      isEmail: {
        type: Boolean,
        default: false,
      },
      validationString: {
        type: String,
        default: '', // https://vee-validate.logaretm.com/v3/guide/rules.html#installing-all-rules
      },
      validationRequired: {
        type: Array,
        default: () => [''], // https://vee-validate.logaretm.com/v3/guide/required-fields.html#creating-a-required-rule
      },
      // Как валидировать форму:
      // https://vee-validate.logaretm.com/v3/advanced/refactoring-forms.html#extracting-input-fields
    },
    data () {
      return {
        active: false,
        innerValue: '',
      };
    },
    created () {
      this.addValidation();
    },
    methods: {
      addValidation () {
        if (this.validationString) {
          Object.keys(rules).forEach(rule => {
            extend(rule, {
              ...rules[rule], // copies rule configuration
              message: messages[rule]?.replace('{field}', this.name), // assign message
            });
          });
        }
        if (this.validationString.includes('trimmed')) {
          extend('trimmed', {
            validate (value) {
              return !/^\s+|\s+$/.test(value);
            },
            message: this.$t('notifications.fieldCanNotStartWithSpace'),
          });
        }
        if (this.validationString.includes('required')) {
          const testRequired = (value) => !this.validationRequired.includes(value);
          extend('required', {
            validate (value) {
              return {
                required: true,
                valid: testRequired(value),
              };
            },
            computesRequired: true,
            message: this.$t('notifications.fillThisField'),
          });
        }
      },
      checkEmail () {
        if (this.isEmail) {
          const isValidEmail = emailRegex.test(this.innerValue);
          if (!isValidEmail) {
            this.$noty.error('Invalid email');
            return false;
          }
          return true;
        }
      },
      addNewItem () {
        if (this.checkEmail()) {
          this.$emit('add', this.innerValue);
          this.innerValue = '';
        }
      },
      handleBlur () {
        this.active = false;
        const isValidEmail = emailRegex.test(this.innerValue);
        if (!this.isNeedSubmitButton && isValidEmail) {
          this.addNewItem();
        }
      },
    },
  };
</script>
<style lang="scss">
.fillable-input {
  &__inner {
    max-width: 100%;
    overflow-y: scroll;
    @include flex-row;
    gap: 4px;
    flex-wrap: wrap;
    padding: 8px 12px;
    border: $border;
    border-radius: $border-radius-big;

    &_danger {
      border: 1px solid $cl-danger;
    }

    &_active {
      border: 1px solid var(--primary-cl);
    }
    &_nude {
      border: none;
      padding: 0;
      border-radius: 0;
    }
  }
  &__item {
    flex-grow: 1;
    border: none;
    // max-width: 150px;
  }
}
</style>
