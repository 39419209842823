function setHubSpot (supportId) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'hs-script-loader';
  script.async = true;
  // script.defer = true;
  script.src = `//js-na1.hs-scripts.com/${supportId}.js`;

  const body = document.getElementsByTagName('body')[0];
  body.appendChild(script);
}
export { setHubSpot };
