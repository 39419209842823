import { useStore } from '@/store';
import { computed } from 'vue';
import { deleteSignature, updateSignature, addSignature } from '@/api/credentialsMethods';

const useSignatures = () => {
  const store = useStore();
  const signatures = computed(() => store.getters['signatures/signaturesList']);
  const selectedCredential = computed(() => store.getters['credentials/selectedCredential']);
  // может вызываться тогда когда нет креда
  const credentialId = computed(() => selectedCredential.value?._id);

  const getSignatures = () => {
    return signatures.value ? [...signatures.value] : [];
  };

  const setSignatures = async (needRequest = false) => {
    if (!signatures.value || needRequest) {
      await store.dispatch('signatures/SET_SIGNATURES_LIST', credentialId.value);
    }
  };

  const setUpdatedSignatures = async (credId, signatureId, signatureName, signatureMessage, signatureIsDefault) => {
    const response = await updateSignature(credId, signatureId, signatureName.trim(), signatureMessage, signatureIsDefault);
    store.commit('signatures/UPDATE_SIGNATURES_LIST', response);
    return response;
  };

  const setAddedSignature = async (credId, signatureId, signatureName, signatureMessage, signatureIsDefault) => {
    const response = await addSignature(credId, signatureId, signatureName, signatureMessage, signatureIsDefault);
    store.commit('signatures/ADD_SIGNATURE', response);
    return response;
  };

  const setDeletedSignature = async (credId, signature) => {
    await deleteSignature(credId, signature._id);
    store.commit('signatures/DELETE_SIGNATURE', signature);
  };

  return { setSignatures, getSignatures, setUpdatedSignatures, setAddedSignature, setDeletedSignature, signatures };
};

export {
  useSignatures
};
