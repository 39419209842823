import { MessageTypes } from '@/types/messages';
import { htmlParse } from '@/utils/externalScripts/htmlParser';

export default {
  namespaced: true,
  state: {
    draftMessages: JSON.parse(localStorage.getItem('draftMessages')) || {},
  },
  getters: {
    draftMessages (state) {
      return state.draftMessages;
    },
  },
  mutations: {
    UPDATE_DRAFT_MESSAGE (state, { profileId, draft, type, subject }) {
      const update = {
        [type]: {
          draft,
          subject,
        },
      };

      const profileDraftMessage = state.draftMessages[profileId];

      if (profileDraftMessage) {
        state.draftMessages[profileId] = { ...profileDraftMessage, ...update };
      } else {
        state.draftMessages[profileId] = update;
      }

      const newMessage = type === MessageTypes.EMAIL
        ? htmlParse(draft)
        : draft;

      if (newMessage) {
        state.draftMessages[profileId].last = newMessage;
      } else {
        const draftExist = Object.keys(profileDraftMessage).length > 1;
        let parsedPrevDraft = '';

        if (draftExist) {
          const prevDraft = Object.values(profileDraftMessage)[0].draft;
          parsedPrevDraft = htmlParse(prevDraft);
        }
        const isPrevMessageChange = state.draftMessages[profileId].last === parsedPrevDraft;

        if (parsedPrevDraft && !isPrevMessageChange) {
          state.draftMessages[profileId].last = parsedPrevDraft;
        } else {
          delete state.draftMessages[profileId].last;
        }
      }

      // TODO remove when we move to vue 3 (reactivity trouble)
      state.draftMessages = { ...state.draftMessages };

      localStorage.setItem('draftMessages', JSON.stringify(state.draftMessages));
    },
  },
};
