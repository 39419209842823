import i18n from '@/i18n';
import { computed } from 'vue';

export const AiLabelsEnum = Object.freeze({
  INTERESTED: 'interested',
  NOT_INTERESTED: 'not_interested',
  OUT_OF_OFFICE: 'out_of_office',
  MAKING_AN_INTRODUCTION: 'making_an_introduction',
  WRONG_CONTACT: 'wrong_contact',
  FOLLOW_UP_LATER: 'follow_up_later',
  HARD_NO: 'hard_no',
  neutral: 'neutral',
});

// TODO: add translation
export const AiLabelsNames = computed(() => ({
  interested: i18n.t('labels.aiLabels.interested'),
  not_interested: i18n.t('labels.aiLabels.notInterested'),
  out_of_office: i18n.t('labels.aiLabels.outOfOffice'),
  making_an_introduction: i18n.t('labels.aiLabels.makingAnIntroduction'),
  wrong_contact: i18n.t('labels.aiLabels.wrongContact'),
  follow_up_later: i18n.t('labels.aiLabels.followUpLater'),
  hard_no: i18n.t('labels.aiLabels.hardNo'),
  neutral: i18n.t('labels.aiLabels.neutral'),
}));
export const EmailsLabelsNames = computed(() => ({

  email_sent: i18n.t('labels.emailLabels.emailSent'),
  clicked_email_link: i18n.t('labels.emailLabels.clickedEmailLink'),
  delete_label: i18n.t('labels.emailLabels.deleteLabel'),
  clicked_message_link: i18n.t('labels.emailLabels.clickedMessageLink'),
}));

export const AiLabelsColors = Object.freeze({
  interested: '#96C258',
  not_interested: '#DC3545',
  out_of_office: '#6AA9DC',
  making_an_introduction: '#A26289',
  wrong_contact: '#FFA011',
  follow_up_later: '#3E7496',
  hard_no: '#8B0000',
  neutral: '#343A40',
});
