/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import moment from 'moment';
/**
 * Get timezone offset in minutes
 * @returns timezone offset in minutes, e.g. `+3 -> +180, -6 -> -360`
 */
export const getTimezoneOffset = () => {
    return new Date().getTimezoneOffset() * -1;
};
/**
 * Generate months periods
 * @param months Number of months periods
 * @param direction Forward = `1` / Backward = `-1`
 * @returns Array of months periods
 */
export const getMonthsPeriods = (months, direction = 1) => {
    const periods = [];
    // Protection for unexpected direction value
    const _direction = direction >= 0 ? 1 : -1;
    const now = moment(Date.now()).utc();
    for (let i = 0; i < months; i++) {
        periods.push({
            start: now.startOf('month').format('YYYY-MM-DD'),
            end: now.endOf('month').format('YYYY-MM-DD'),
        });
        now.add(_direction, 'month');
    }
    return periods;
};
/**
 * Get timezone name with GMT offset
 *
 * eg. `Europe/Moscow` --> `Europe/Moscow (GMT +3)`
 *
 * @param {string} tzValue Valid timezone value without spaces
 * @param {string} tzName Timezone name
 */
export function getTzNameWithGmt(tzValue, tzName) {
    try {
        const options = { timeZone: tzValue, timeZoneName: 'shortOffset' };
        const dateText = Intl.DateTimeFormat(['en-US'], options).format(new Date());
        const offsetText = dateText.slice(dateText.indexOf('GMT') + 3) || '+0';
        const name = tzName + ' (GMT ' + offsetText + ')';
        return name;
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return tzName;
    }
}
