import { PausedReason } from '@/enums/profile';
import { useI18n } from '@/i18n';
import { useSocialName } from '@/utils/global/socialName';
export const usePausedText = () => {
    const { t } = useI18n();
    const socialName = useSocialName();
    const getPausedText = (pausedReason) => {
        switch (pausedReason) {
            case PausedReason.TALKED_BEFORE:
            case PausedReason.TALKED_BEFORE_WORKFLOW:
                return t('inboxPage.chatSection.infoSidebar.campaignInfo.pausedReasons.talkedBefore');
            case PausedReason.FAILED_EMAIL:
                return t('inboxPage.chatSection.infoSidebar.campaignInfo.pausedReasons.failedEmail');
            case PausedReason.FAILED_MESSAGE:
                return t('inboxPage.chatSection.infoSidebar.campaignInfo.pausedReasons.failedMessage', { socialName: socialName.value });
            case PausedReason.CALENDLY:
                return t('inboxPage.chatSection.infoSidebar.campaignInfo.pausedReasons.calendly');
            case PausedReason.MANUAL:
                return t('inboxPage.chatSection.infoSidebar.campaignInfo.pausedReasons.manual');
            case PausedReason.INMAIL_WITH_MESSAGES:
                return t('inboxPage.chatSection.infoSidebar.campaignInfo.pausedReasons.inmailWithMessages');
        }
        return '';
    };
    return { getPausedText };
};
