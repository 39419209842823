import { useSnack } from '@/lib/useSnack';

export const copyValue = (value) => {
  // Для копирования в буфер обмена создаю инпут => select => copy => delete el
  const { $snack } = useSnack();
  const $input = document.createElement('input');
  $input.value = value;
  document.body.appendChild($input);
  $input.select();
  document.execCommand('Copy');
  $input.remove();
  $snack.success('Copied successfully');
};
