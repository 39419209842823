<template>
  <div class="flex-column flex-gap-4">
    <div class="flex-row flex-gap-4">
      <default-input
        icon="bx-search"
        :placeholder="$t('placeholders.search')"
        size="xs"
        :value="searchData.qeury"
        :is-fulled="true"
        :debounce="300"
        @input="makeSearch"
      />
      <filters-block
        :filters="searchData.filters"
        @update-filters="$emit('update-filters', $event)"
      />
    </div>

    <div class="flex flex-gap-8">
      <default-tooltip
        class="flex-1"
        data-closable="groupFilters"
        :items="groupData"
        list-position="left"
        :has-triangle="false"
        :is-fulled="true"
        @choose="groupDataHandler"
      >
        <template #default>
          <default-button
            :title="groupButtonName"
            icon-name="bxs-chevron-down"
            color="tertiary"
            form="rectangle"
            size="m"
            :has-right-icon="true"
            :is-fulled="true"
          />
        </template>
      </default-tooltip>
      <div class="flex flex-gap-4 align-item-center flex-1">
        <default-tooltip
          class="flex-1"
          data-closable="messageFilters"
          :items="channelFilters"
          list-position="right"
          :has-triangle="false"
          :is-fulled="true"
          @choose="messageFilterHandler"
        >
          <template #default>
            <default-button
              :title="channelName"
              icon-name="bxs-chevron-down"
              color="tertiary"
              form="rectangle"
              size="m"
              :has-right-icon="true"
              :is-fulled="true"
            />
          </template>
        </default-tooltip>
        <help-icon v-bind="helpChannelTooltipOptions" />
      </div>
    </div>
  </div>
</template>
<script>
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import FiltersBlock from '@/components/base/filtersBlock/FiltersBlock';
  import { capitalizeFilterMixin } from '@/mixins/capitalizeFilterMixin';
  import HelpIcon from '@/components/helpIcon/HelpIcon.vue';
  import { TooltipPosition } from '@/plugins/tooltip/enums';

  export default {
    name: 'FiltersDialogs',
    components: {
      HelpIcon,
      DefaultInput,
      DefaultTooltip,
      DefaultButton,
      FiltersBlock,
    },
    mixins: [capitalizeFilterMixin],
    props: {
      searchData: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        groupData: [
          {
            value: 'all',
            name: this.$t('inboxPage.chatsSidebar.header.filters.all'),
          },
          {
            value: 'unread',
            name: this.$t('inboxPage.chatsSidebar.header.filters.unread'),
          },
          {
            value: 'replied',
            name: this.$t('inboxPage.chatsSidebar.header.filters.replies'),
          },
          {
            value: 'starred',
            name: this.$t('status.starred'),
          },
          {
            value: 'archived',
            name: this.$t('status.archived'),
          },
          {
            value: 'no_reply',
            name: this.$t('inboxPage.chatsSidebar.header.filters.noReply'),
          },
        ],
        helpChannelTooltipOptions: {
          content: 'If you select all channels, then there may be a problem associated with incorrect sorting of contacts by the time of the last message',
          tooltipMaxWidth: 266,
          tooltipPosition: TooltipPosition.TOP_RIGHT,
        },
      };
    },
    computed: {
      channelFilters () {
        return [
          {
            value: 'global',
            name: this.$t('inboxPage.chatsSidebar.header.filters.allChannels'),
          },
          {
            value: 'linkedin',
            name: this.$t('inboxPage.chatsSidebar.header.filters.social', { socialName: this.$socialName }),
          },
          {
            value: 'email',
            name: this.$t('inboxPage.chatsSidebar.header.filters.email'),
          },
        ];
      },
      groupButtonName () {
        return this.groupData.find(e => e.value === this.searchData.group).name;
      },
      channelName () {
        return this.channelFilters.find(e => e.value === this.searchData.channel).name;
      },
    },
    methods: {
      groupDataHandler (item) {
        this.$emit('update-group', item.value);
      },
      messageFilterHandler (item) {
        this.$emit('update-channel', item.value);
      },
      makeSearch (e) {
        this.$emit('update-query', e);
      },
    },
  };
</script>
