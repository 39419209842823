var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function({ passes }){return [_c('form',{staticClass:"flex-row flex-gap-16",on:{"submit":function($event){$event.preventDefault();return passes(_vm.addNewItem)}}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"tag":"div","rules":_vm.validationString},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{class:[
          'fillable-input__inner',
          {
            'fillable-input__inner_danger': errors.length,
            'fillable-input__inner_nude': _vm.isNude,
            'fillable-input__inner_active': (_vm.active && !errors.length),
          }
        ]},[(_vm.items.length)?_c('div',{staticClass:"flex-row flex-gap-4 flex-wrap"},_vm._l((_vm.items),function(item,i){return _c('default-label',{key:`${item}${i}`,attrs:{"custom-type":_vm.labelType,"label-name":item,"size":"xs","has-button":true},nativeOn:{"click":function($event){return _vm.$emit('delete', item)}}})}),1):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],staticClass:"fillable-input__item",class:`text_${_vm.inputSize}`,attrs:{"id":_vm.inputId,"name":_vm.inputName,"placeholder":_vm.inputPlaceholder},domProps:{"value":(_vm.innerValue)},on:{"focus":function($event){_vm.active = true},"blur":_vm.handleBlur,"input":function($event){if($event.target.composing)return;_vm.innerValue=$event.target.value}}}),(errors.length)?_vm._l((errors),function(error,i){return _c('p',{key:`${error}${i}`,staticClass:"color_danger text_xs"},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2)]}}],null,true)}),(_vm.isNeedSubmitButton)?_c('default-button',{attrs:{"type":"submit","title":_vm.buttonTitle,"color":_vm.buttonColor,"size":_vm.buttonSize,"form":_vm.buttonForm}}):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }