<template>
  <date-picker
    class="task-card-info__date-picker"
    :value="selectedOption.value"
    value-type="YYYY-MM-DD HH:mm:ss"
    :editable="false"
    :disabled="!isEditable"
    :placeholder="$t('placeholders.selectPeriod')"
    :append-to-body="false"
    @change="updateSelectedDate"
    @click.native.stop
  >
    <template #input>
      <slot />
    </template>
  </date-picker>
</template>

<script setup>
  import DatePicker from 'vue2-datepicker';
  import { useSnack } from '@/lib/useSnack';
  import moment from 'moment';
  import 'vue2-datepicker/index.css';

  defineProps({
    selectedOption: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['update:selected-option']);

  const { $snack } = useSnack();

  const updateSelectedDate = (date) => {
    if (new Date(date).valueOf() < Date.now()) {
      $snack.error('You can\'t set a date in the past');
      return;
    }
    const name = moment(date).format('MMMM DD, YYYY');
    emit('update:selected-option', { name, value: date });
  };
</script>

<style lang="scss">
.task-card-info {
  &__date-picker {
    width: auto;
    display: block;

    &.disabled {
      opacity: 1;
      pointer-events: auto;
    }

    .mx-icon-calendar,
    .mx-icon-clear {
      display: none;
    }
  }
}
</style>
