function setTagScript (user) {
  window.hasOwnProperty('ire') && window.ire('identify', { customerId: user?._id || '', customerEmail: user?.email || '' });
}
function impactTrackScript (user, space = null, isPurchase = false) {
  const id = isPurchase ? 38727 : 38726;
  window.hasOwnProperty('ire') && window.ire('trackConversion', id, {
    orderId: user._id,
    customerId: user._id,
    customerEmail: user.email,
    customerStatus: isPurchase ? 'EXISTING' : 'NEW',
    currencyCode: isPurchase ? space.subscription.currency : 'USD',
    items: [
      {
        subTotal: isPurchase ? Math.round(space.subscription.amount / 100) : '',
        category: isPurchase ? 'subscription' : '',
        sku: isPurchase ? space.subscription.tier : '',
        quantity: 1,
        name: isPurchase ? space.name : '',
      },
    ],
  },
  {
    verifySiteDefinitionMatch: true,
  }
  );
}

export { setTagScript, impactTrackScript };
