<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end-2">
    <modal
      :key="name"
      :name="name"
      :width="width"
      height="auto"
      classes="component"
      :adaptive="true"
      @closed="handleClose"
    >
      <div class="modal">
        <p>
          {{ title }}
        </p>
        <p class="modal__small-text text">
          {{ text }}
        </p>
        <div class="modal__buttons">
          <div
            class="primary-btn primary-btn_gray"
            @click="discardChanges"
          >
            <span class="text">{{ cancelText }}</span>
          </div>
          <div
            class="primary-btn"
            @click="saveChanges"
          >
            <span class="text"> {{ primaryText }} </span>
          </div>
        </div>
      </div>
    </modal>
  </portal>
</template>

<script setup>
  import { watch } from 'vue';
  import { useModal } from '@/use/useModal';

  // name: 'ConfirmChangesModal',

  const props = defineProps({
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: function () {
        return this.$t('modals.confirmChanges.default.title');
      },
    },
    text: {
      type: String,
      default: function () {
        return this.$t('modals.confirmChanges.default.text');
      },
    },
    cancelText: {
      type: String,
      default: function () {
        return this.$t('modals.confirmChanges.default.cancelText');
      },
    },
    primaryText: {
      type: String,
      default: function () {
        return this.$t('modals.confirmChanges.default.primaryText');
      },
    },
    name: {
      type: String,
      default: 'confirmChangesModal',
    },
    width: {
      type: String,
      default: '550px',
    },
    stay: {
      type: Boolean,
      default: false,
    },
    next: {
      type: Function,
      default: () => {
        this.$router.push('/');
      },
    },
  });
  const $modal = useModal();
  const emit = defineEmits(['update:show', 'save-changes', 'cancel']);
  watch(() => props.show, () => props.show ? $modal.show(props.name) : $modal.hide(props.name));
  function discardChanges () {
    emit('update:show', false);
    emit('cancel');
    props.next();
  }
  function saveChanges () {
    emit('save-changes');
    emit('update:show', false);
    if (!props.stay) {
      props.next();
    }
  }
  const handleClose = () => {
    emit('update:show', false);
  };
</script>

<style lang="scss" scoped>
  .modal {
    padding: 5%;
    display: grid;
    justify-items: center;
    background-color: #F1F3F4;
    height: 100%;
    border-radius: 15px;
    color: black;
    font-size: 1.275rem;
    font-weight: 600;
    grid-row-gap: 43px;

    &__small-text {
      font-size: 0.9975rem;
      color: #5F5F5F;
      font-weight: normal;
    }

    &__buttons {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 12px;
      white-space: nowrap;
    }
 }

  .primary-btn {
    width: 100%;
    height: 40px;
    font-size: 1.0575rem;
  }
</style>
