/*
© Copyright, Dexima Inc.
2023 — All rights reserved.
*/

import { onMounted, onUnmounted, watch } from 'vue';

export class ClosableData {
  /**
   * @param {import('vue').Ref<HTMLElement|import('vue').Component | null>} closable
   * @param {import('vue').Ref<HTMLElement|import('vue').Component| null>} excluded
   * @param {import('vue').Ref<Boolean>} show
   */
  constructor (closable, excluded, show) {
    this.closable = closable;
    this.excluded = excluded;
    this.show = show;
  }
}

/**
 * @param {import('vue').Ref<HTMLElement|import('vue').Component>} source
 * @param {HTMLElement} element
 */
const containsElement = (source, element) => {
  if (!source?.value) return false;

  if ('$el' in source.value) {
    return source.value.$el.contains(element);
  } else {
    return source.value.contains(element);
  }
};

/**
 * @param {ClosableData} closableData
 * @param {() => void} [callback] Callback on close event
*/
export const useClosable = (closableData, callback = () => ({})) => {
  const { closable, excluded, show } = closableData;

  watch(show, (newValue) => {
    if (newValue) {
      addListener();
    } else {
      removeListener();

      callback();
    }
  });

  // Add listener if closable is initially shown
  onMounted(() => {
    if (show.value) {
      addListener();
    }
  });

  onUnmounted(() => {
    removeListener();
  });

  /**
   * @param {MouseEvent} event
  */
  const closeHandler = (event) => {
    const clickedClosable = containsElement(closable, event.target);
    const clickedExcluded = containsElement(excluded, event.target);

    if (clickedExcluded) return;

    if (!clickedClosable) {
      show.value = false;
    }
  };

  const addListener = () => {
    document.addEventListener('click', closeHandler, { capture: true });
  };

  const removeListener = () => {
    document.removeEventListener('click', closeHandler, { capture: true });
  };
};
