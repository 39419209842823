<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    ref="tooltip"
    class="tooltip-block"
    tabindex="0"
    @click.stop="() => {}"
  >
    <div
      class="tooltip-block__content "
      :style="styles"
    >
      <template v-for="(itemsSet, index) in allItems">
        <p
          :key="itemsSet.category._id + index"
          class="tooltip-block__header"
        >
          {{ itemsSet.category.name }}
        </p>
        <template v-if="itemsSet.items && itemsSet.items.length">
          <template v-for="(item, itemIndex) in itemsSet.items">
            <div
              :key="itemsSet.category._id + item._id + itemIndex"
              class="tooltip-block__item"
            >
              <checkbox-tooltip-item
                :item="item"
                :category="itemsSet.category"
                :chosen-items="chosenItems"
                :checkmark-style="item.checkmarkStyle || null"
                @click.native.stop="debounceClick(item, itemsSet.category)"
              />
              <div class="tooltip-block__item-actions">
                <slot
                  name="itemAction"
                  :item="item"
                  :index="itemIndex"
                />
              </div>
            </div>
          </template>
        </template>
        <p
          v-else
          :key="itemsSet.category._id+'empty'"
          class="secondary-text text-12 center"
        >
          NO ITEMS
        </p>
        <div
          v-if="index !== allItems.length - 1"
          :key="'sep' + itemsSet.category._id + index"
          class="separator"
        />
      </template>
      <div
        v-if="showActions"
        class="tooltip-block__action-buttons"
      >
        <div class="separator tooltip-block__actions-separator" />
        <slot name="tooltipActions" />
      </div>
    </div>
  </div>
</template>
<script>
  import CheckboxTooltipItem from './CheckboxTooltipItem.vue';
  import { debounce } from 'vue-debounce';

  export default {
    name: 'CheckboxTooltip',
    components: { CheckboxTooltipItem },
    props: {
      showActions: {
        type: Boolean,
        default: false,
      },
      styles: {
        type: Object,
        default: null,
      },

      chosenItems: {
        type: Array,
        default: () => [],
      },
      passThrough: {
        type: Boolean,
        default: false,
      },
      allItems: {
        type: Array,
        default: () => [
          {
            category: {
              name: 'FILTERS',
              _id: 'items',
            },
            items: [
              {
                name: 'Unread messages',
                _id: 'unread',
              },
              {
                name: 'Started within 7 days',
                _id: 'started_7days',
              },
              {
                name: 'No reply within 7 days',
                _id: 'no_reply_7days',
              },
              {
                name: 'Only paused campaigns',
                _id: 'paused_campaigns',
              },
              {
                name: 'Only with notes',
                _id: 'with_notes',
              },
            ],
          },
          {
            category: {
              name: 'By CAMPAIGNS',
              _id: 'workflows',
            },
            items: [
              {
                name: 'VP of Sales in London, main test', // worfklow name
                _id: '123456', // workflow id
              },
              {
                name: 'Sales Directors in Las Vegas,  second te...',
                _id: '1234567',
              },
            ],
          },
          {
            category: {
              name: 'By STATUS IN CAMPAIGN',
              _id: 'statuses',
            },
            items: [
              {
                name: 'Got Reply',
                _id: 'got_reply',
              },
              {
                name: 'Lead',
                _id: 'interested',
              },
              {
                name: 'Customer',
                _id: 'lead',
              },
            ],
          },
        ],
      },
    },

    created () {
      this.debounceClick = debounce((item, category, keepActive = false) => {
        this.handleItemClick(item, category, keepActive);
      }, 150);
    },
    methods: {
      focus () {
        this.$refs.tooltip.focus();
      },
      setActive (chooseItem) {
        let item = null;
        // ищем первый элемент с таким значением
        this.allItems.some((itemsSet) => {
          item = itemsSet.items.find((item) => item._id === chooseItem);
          // добавляем
          if (item) {
            this.handleItemClick(item, itemsSet.category, true);
          }
          // когда вернет тру - нашли тот элемент
          return !!item;
        });
      },
      handleItemClick (item, category, keepActive = false) {
        const chosenItemsCopy = JSON.parse(JSON.stringify(this.chosenItems));
        // ищем в уже выбранных фильтрах категорию
        const categoryIndex = chosenItemsCopy.findIndex(
          (chosenItem) => chosenItem.category._id === category._id
        );
        if (categoryIndex > -1) {
          // в категории ищем элемент

          const itemIndex = chosenItemsCopy[categoryIndex].items.findIndex(
            (chosenFilter) => chosenFilter._id === item._id
          );
          // если нашли элемент и не передан флаг - оставить активным
          if (itemIndex > -1 && !keepActive) {
            chosenItemsCopy[categoryIndex].items.splice(itemIndex, 1);
            this.$emit('delete-profile-label', item._id, chosenItemsCopy);
          } else {
            chosenItemsCopy[categoryIndex].items.push(item);
            this.$emit('add-profile-label', item._id, chosenItemsCopy);
          }
        } else {
          chosenItemsCopy.push({
            category,
            items: [item],
          });
        }
        this.updateParsedItems(chosenItemsCopy);
        if (!this.passThrough) {
          this.$emit('update:chosenItems', chosenItemsCopy);
        } else {
          this.$emit('update-items', chosenItemsCopy);
        }
      },
      updateParsedItems (chosenItemsCopy) {
        const parsedItems = {};
        chosenItemsCopy.forEach((itemSet) => {
          const category = itemSet.category._id;
          parsedItems[category] = [];
          itemSet.items.forEach((item) => {
            parsedItems[category].push(item._id);
          });
        });
        this.$emit('update-parsed-items', parsedItems);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .separator {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .tooltip-block {
    position: absolute;
    z-index: 100;
    &__content {
      width: 100%;
      max-height: calc(100vh - 222px);
      padding: 16px 14px;
      position: absolute;
      display: flex;
      flex-flow: column;
      color: var(--text-color);
      text-transform: capitalize;
      border-radius: 12px;
      gap: 8px;
      box-shadow: 0 0 5px 1px var(--tooltip-shadow);
      background-color: var(--background-color);
      overflow: auto;
    }

    &__header {
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 600;
      margin-bottom: 8px;
      font-size: 0.6225rem;
      line-height: 12px;
      color: var(--main-text-cl);
    }
    &__item {
      width: 100%;
      display: flex;
      cursor: pointer;
      flex-flow: row nowrap;
      column-gap: 8px;
    }
    &__item-actions {
      display: flex;
      flex-flow: row nowrap;
      margin-left: auto;
    }
    &__separator{
    width: calc(100% + 28px);
    position: relative;
    margin-bottom: 8px;
    top:0;
    left: -14px;
  }
    &__action-buttons{
    display: flex;
    flex-flow: column;
    position: relative;
  }
  &__action-button {
    padding: 8px 20px;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-weight: 600;
    transition-duration: .3s;
    white-space: nowrap;

    &:hover {
      background: var(--active-bg-cl);
    }
  }

  }
  @media (max-width: 768px) {
.tooltip-block__content {
  max-height: calc(100vh - 222px) !important;
  min-width: 300px !important;
}
}
</style>
