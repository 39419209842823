<template>
  <li class="flex-column flex-align-items-start">
    <div
      :class="[
        'inbox-message__body',
        'inbox-message__body_' + (message.is_me ? 'right' : 'left')
      ]"
      :style="bgColor"
    >
      <div ref="messageText">
        <!-- Place to insert -->
      </div>

      <default-button
        v-if="['pending', 'failed'].includes(message.status)"
        class="inbox-message__button"
        icon-name="bx-x"
        color="danger"
        form="circle"
        :is-disabled="isDeleting"
        size="s"
        @action="deleteMessageHandler"
      />
    </div>

    <div class="flex flex-gap-4 color_tertiary">
      <p class="text_xs">
        {{ date }}
      </p>

      <i
        v-if="message.mode === 'sales_navigator'"
        class="bx bx-compass icon_size_16"
      />

      <i
        v-if="message.is_me"
        :class="['bx icon_size_16', icon]"
      />
    </div>
  </li>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { httpRegex, urlRegex, inboxEmailRegex } from '@/data/regexes';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import { deleteMessage } from '@/api/messagesMethods';
  import { formatPhoneNumber } from '@/utils/dialer/utils';

  export default {
    name: 'CardMessages',
    components: {
      DefaultButton,
    },
    props: {
      message: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isDeleting: false,
        approvedStatuses: [
          'wait',
          'declined',
          'approved',
        ],
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      icon () {
        switch (this.message.status) {
        case 'pending':
          return 'bx-time color_warning';
        case 'seen':
          return 'bx-check-double color_success';
        case 'sent':
          return 'bx-check color_primary';
        case 'failed':
          return 'bx-x color_danger';
        default:
          return '';
        }
      },
      bgColor () {
        if (!this.message.is_me) {
          if (this.approvedStatuses.includes(this.message.status)) {
            return { background: `${this.user.config.color}1A` };
          } else if (this.$route.name === 'my-prospects') {
            return { background: '#F9F9F9' };
          }
        }
        return '';
      },
      date () {
        const myTimeZone = new Date().getTimezoneOffset();
        let momentDate = this.selectedCredential.ui_pc_timezone
          ? moment(new Date(new Date(this.message.created_at) - myTimeZone * 1000 * 60))
          : moment(this.message.created_at).utcOffset(this.selectedCredential.timezone_offset - myTimeZone);
        momentDate = momentDate.format('MMM DD, h:mm a');
        return momentDate || this.$t('status.pending');
      },
    },
    mounted () {
      this.transformMessage();
    },
    methods: {
      formatPhoneNumber,
      transformMessage () {
        const p = document.createElement('p');
        p.setAttribute('class', 'inbox-message__text');
        let text = this.message.text;
        let textWithLinks = '';
        let links = text.match(urlRegex);
        const emails = text.match(inboxEmailRegex);
        links = emails
          ? (links ? [...links, ...emails] : emails)
          : links;

        if (links) {
          links.forEach((link) => {
            // create link
            const a = document.createElement('a');
            let href = link;
            if (href.match(inboxEmailRegex)) {
              href = 'mailto:' + href;
            } else {
              if (!href.match(httpRegex)) {
                href = 'https://' + href.replace('www.', '');
              }
            }
            a.setAttribute('href', href);
            a.setAttribute('target', '_blank');
            a.setAttribute('class', 'text_underlined');
            a.innerHTML = link;

            const startIdx = text.indexOf(link);
            const endIdx = text.indexOf(link) + link.length;

            textWithLinks += text.slice(0, startIdx) + a.outerHTML;
            text = text.slice(endIdx);
          });
          // Add text after last link index
          textWithLinks += text;
        }
        p.innerHTML = textWithLinks || text;
        this.$refs.messageText.innerHTML = p.outerHTML;
      },
      async deleteMessageHandler () {
        try {
          this.isDeleting = true;
          await deleteMessage(this.message._id, this.selectedCredential._id);
          this.$emit('delete-message', this.message);
        } catch (e) {
          this.$noty.error(e.message);
          this.isDeleting = false;
        }
      },
    },
  };
</script>
<style lang="scss">
.inbox-message {
  &__text {
    @include text_s;
    word-break: break-word;
    white-space: pre-line;
  }

  &__body {
    position: relative;
    padding: 8px 16px;

    &_right {
      background: var(--primary-cl);
      border-radius: 12px 12px 0 12px;

      p {
        color: $cl-white;
      }
    }

    &_left {
      background: var(--background-color);
      border-radius: 12px 12px 12px 0;
      border: 0.5px solid var(--border);

      p {
        color: var(--text-color);
      }
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: calc(100% + 4px);
  }
}
</style>
