import { getCustomDomains } from '@/api/linkTrackingMethod';

export default {
  namespaced: true,
  state: {
    customDomains: null,
  },
  getters: {
    customDomains (state) {
      return state.customDomains;
    },
  },
  mutations: {
    SET_CUSTOM_DOMAINS (state, payload) {
      state.customDomains = payload;
    },
    UPDATE_CUSTOM_DOMAINS (state, payload) {
      let customDomains = state.customDomains?.slice(0) || [];
      if (!customDomains.length) {
        state.customDomains.push(payload);
      }

      if (payload.is_default_for_link_tracking) {
        customDomains = customDomains.map(domain => {
          domain.is_default_for_link_tracking = false;
          return domain;
        });
      }
      if (payload.is_default_for_video_scenario) {
        customDomains = customDomains.map(domain => {
          domain.is_default_for_video_scenario = false;
          return domain;
        });
      }
      const existingIndex = customDomains.findIndex(domain => domain._id === payload._id);

      if (existingIndex > -1) {
        customDomains.splice(existingIndex, 1, payload);
      } else {
        customDomains.push(payload);
      }
      state.customDomains = customDomains;
    },
    ADD_DOMAIN (state, payload) {
      let customDomains = state.customDomains?.slice(0) || [];

      if (payload.is_default_for_link_tracking) {
        customDomains = customDomains.map(domain => {
          domain.is_default_for_link_tracking = false;
          return domain;
        });
      }
      if (payload.is_default_for_video_scenario) {
        customDomains = customDomains.map(domain => {
          domain.is_default_for_video_scenario = false;
          return domain;
        });
      }

      customDomains.push(payload);

      state.customDomains = customDomains;
    },
    DELETE_DOMAIN (state, payload) {
      state.customDomains = state.customDomains.filter(domain => domain._id !== payload._id);
    },
    UPDATE_USAGE_COUNTER (state, { domain, oldDomainId, flow }) {
      state.customDomains = state.customDomains.map(customDomain => {
        const isCurrent = customDomain._id === domain._id;
        const isOld = customDomain._id === oldDomainId;
        if (flow === 'video_scenario') {
          if (isCurrent) {
            customDomain.video_usage_counter++;
          }
          if (isOld) {
            customDomain.video_usage_counter--;
          }
        } else {
          if (isCurrent) {
            customDomain.workflow_usage_counter++;
          }
          if (isOld) {
            customDomain.workflow_usage_counter--;
          }
        }
        return customDomain;
      });
    },
    USE_DOMAIN_FOR_ALL (state, { domain, flow }) {
      state.customDomains = state.customDomains.map(customDomain => {
        const isCurrent = customDomain._id === domain._id;
        if (flow === 'video_scenario') {
          customDomain.video_usage_counter = 0;
        } else {
          customDomain.workflow_usage_counter = 0;
        }
        if (isCurrent) {
          customDomain = domain;
        }
        return customDomain;
      });
    },
  },
  actions: {
    async SET_CUSTOM_DOMAINS ({ commit }) {
      const domains = await getCustomDomains();
      commit('SET_CUSTOM_DOMAINS', domains);
    },
  },
};
