/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import store from '@/store';
store.commit('mobile/SET_MOBILE_CHECK');
// TODO rewrite workflows components to campaigns
const WorkflowsPage = () => import('@/views/workflows/WorkflowsPage');
const MyCampaignsPage = () => import('@/views/workflows/MyCampaignsPage.vue');
const SmartSequencesPage = () => import('@/views/workflows/SmartSequencesPage');

const EditWorkflow = () => import('@/views/workflows/EditWorkflow');
const DuplicateWorkflowPage = () => import('@/views/workflows/DuplicateWorkflowPage');

const isMobile = store.getters['mobile/isMobile'];

let meta = {};
if (!isMobile) {
  meta = {
    layout: 'DefaultLayout',
    hasSidebar: true,
  };
} else {
  meta = {
    layout: 'MobileLayout',
    hasSidebar: true,
  };
}

const campaigns = [
  {
    path: '/campaigns',
    name: 'campaigns',
    component: WorkflowsPage,
    redirect: { name: 'my-campaigns' },
    children: [
      {
        path: 'my-campaigns',
        name: 'my-campaigns',
        component: MyCampaignsPage,
        meta,
        children: [
          {
            path: ':filter',
            name: 'my-campaigns-filtered',
            component: MyCampaignsPage,
            meta,
          },
        ],
      },
    ],
  },
  {
    path: '/campaigns/duplicate',
    name: 'duplicate-campaign',
    component: DuplicateWorkflowPage,
    meta,
  },
  {
    path: '/campaign/add',
    name: 'add-campaign',
    component: EditWorkflow,
    meta: {
      ...meta,
      feature: 'campaigns',
    },
  },
  {
    path: '/campaign/edit/:id',
    name: 'edit-campaign',
    component: EditWorkflow,
    meta,
  },
  {
    path: '/smart-sequences',
    name: 'smart-sequences',
    component: SmartSequencesPage,
    meta: {
      layout: 'EmptyLayout',
      feature: 'smart_sequences',
    },
  },
];

export default campaigns;
