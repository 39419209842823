<template>
  <div class="flex-column flex-gap-16">
    <h1 class="text_s text_weight_accent pl-2 pr-2 flex-row">
      {{ t('inboxPage.chatSection.infoSidebar.contactsInfo.title') }}

      <help-icon
        class="ml-05"
        v-bind="helpProps"
      />
    </h1>
    <div>
      <p class="text_xs color_tertiary pl-2 pr-2">
        {{ t('inboxPage.chatSection.infoSidebar.contactsInfo.sections.direct.title') }}
      </p>
      <custom-item-contacts
        v-for="contact in directContacts"
        :key="contact.meta.profileKey"
        class="pt-05 pr-2 pb-05 pl-2"
        :contact="contact"
        :profile="profile"
        @change="change(contact.meta.profileKey, $event)"
        @add-phone="emit('add-phone')"
      />
    </div>
    <div>
      <p class="text_xs color_tertiary pl-2 pr-2">
        {{ t('inboxPage.chatSection.infoSidebar.contactsInfo.sections.work.title') }}
      </p>
      <custom-item-contacts
        v-for="contact in workContacts"
        :key="contact.meta.profileKey"
        class="pt-05 pr-2 pb-05 pl-2"
        :contact="contact"
        :profile="profile"
        @change="change(contact.meta.profileKey, $event)"
        @add-phone="emit('add-phone')"
      />
    </div>
    <enriched-block-contacts
      v-if="tierFeatures.statuses?.enrichment?.exist"
      :profile="profile"
      class="pr-2 pl-2"
      @update-profile="emit('update-profile', $event)"
      @add-phone="emit('add-phone')"
    />
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import CustomItemContacts from '@/components/inboxPage/info/basic/contacts/CustomItemContacts.vue';
  import EnrichedBlockContacts from '@/components/inboxPage/info/basic/contacts/EnrichedBlockContacts.vue';
  import HelpIcon from '@/components/helpIcon/HelpIcon.vue';
  import { useI18n } from '@/i18n';
  import { tierFeatures } from '@/data/tiersFeatures';
  import { TooltipPosition } from '@/plugins/tooltip/enums';

  const props = defineProps({
    profile: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['update-profile', 'add-phone']);

  const { t, tc } = useI18n();

  const helpProps = {
    content: 'You can enrich prospect data in two ways:<br>• Using the "Enrich Profile" step in campaigns, this data will appear in the Direct and Work categories<br>• Using the "Enrich" button on the Prospect Finder page, this data will appear in the Enriched category',
    tooltipPosition: TooltipPosition.TOP,
    textAlign: 'left',
  };

  const contacts = ref([
    {
      name: tc('common.email'),
      emptyState: t('status.noEmail'),
      icon: 'bx bx-envelope',
      value: props.profile.email || '',
      meta: {
        category: 'direct',
        profileKey: 'email',
      },
    },
    {
      name: tc('common.phone'),
      emptyState: t('status.noPhone'),
      icon: 'bx bx-phone',
      value: props.profile.formatted_phone || props.profile.phone || '',
      meta: {
        category: 'direct',
        profileKey: 'phone',
      },
    },
    {
      name: t('servicesNames.twitter'),
      emptyState: t('status.noTwitter'),
      icon: 'bx bxl-twitter',
      value: props.profile.twitter || '',
      meta: {
        category: 'direct',
        profileKey: 'twitter',
      },
    },

    {
      name: tc('common.email'),
      emptyState: t('status.noEmail'),
      icon: 'bx bx-envelope',
      value: props.profile.work_email || '',
      meta: {
        category: 'work',
        profileKey: 'work_email',
      },
    },
    // {
    //   name: tc('common.phone'),
    //   emptyState: t('status.noPhone'),
    //   icon: 'bx bx-phone',
    //   value: props.profile.company_phone,
    //   meta: {
    //     category: 'work',
    //     profileKey: 'company_phone',
    //   },
    // },
  ]);

  const directContacts = computed(() => {
    return contacts.value.filter((c) => c.meta.category === 'direct');
  });

  const workContacts = computed(() => {
    return contacts.value.filter((c) => c.meta.category === 'work');
  });

  function change (key, newValue) {
    contacts.value = contacts.value.map((c) => {
      if (c.meta.profileKey === key) {
        c.value = newValue;
      }
      return c;
    });
    const updatedProp = { [key]: newValue };
    emit('update-profile', updatedProp);
  }
</script>
